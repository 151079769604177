import { render, staticRenderFns } from "./ReadingComprehension.vue?vue&type=template&id=59abf097&scoped=true&"
import script from "./ReadingComprehension.vue?vue&type=script&lang=js&"
export * from "./ReadingComprehension.vue?vue&type=script&lang=js&"
import style0 from "./ReadingComprehension.vue?vue&type=style&index=0&id=59abf097&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59abf097",
  null
  
)

export default component.exports